import {AuthorizedHttpClient} from "common/net";
import {ClassifiedAddress} from "../types";

export class ClassifiedAddressRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAllBySearch(search: string): Promise<ClassifiedAddress[]> {
        return this.client.get("v1/addresses/", {
            params: {search},
        });
    }
}
