export const markerColors = [
    {
        id: "red",
        value: "#ff0000",
        label: "Красный",
    },
    {
        id: "pink",
        value: "#ff00bf",
        label: "Розовый",
    },
    {
        id: "violet",
        value: "#9900ff",
        label: "Фиолетовый",
    },
    {
        id: "orange",
        value: "#ff9900",
        label: "Оранжевый",
    },
    {
        id: "green",
        value: "#33cc33",
        label: "Зеленый",
    },
    {
        id: "blue",
        value: "#0099ff",
        label: "Голубой",
    },
    {
        id: "yellow",
        value: "#e6e600",
        label: "Желтый",
    },
];

export const DEFAULT_MARKER_COLOR = markerColors[0];
