import {AuthorizedHttpClient} from "common/net";
import {CreateUserParam, IUserEntity, UpdateProfileParam} from "../types";

export class UserRepository {

    constructor(private readonly client: AuthorizedHttpClient) {
    }

    create(param: CreateUserParam): Promise<void> {
        return this.client.postV0<any>({type: "add_employee", ...param});
    }

    findByToken(): Promise<IUserEntity> {
        return this.client.postV0<IUserEntity>({type: "info_employee"});
    }

    update(id: number, param: CreateUserParam): Promise<void> {
        return this.client.postV0<any>({type: "update_employee", id, ...param});
    }

    updateProfile(param: UpdateProfileParam): Promise<void> {
        return this.client.postV0<any>({type: "update_profile", ...param});
    }
}
